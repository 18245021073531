import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import { localApi } from "utils/headApi";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

// import cardProfile1 from "assets/img/examples/card-profile1.jpg";
// import cardProfile2 from "assets/img/examples/card-profile2.jpg";
// import cardProfile4 from "assets/img/examples/card-profile4.jpg";

// import cardProfile1 from "assets/img/examples/card-profile1.jpg";
// import cardProfile2 from "assets/img/examples/card-profile2.jpg";
// import cardProfile4 from "assets/img/examples/card-profile4.jpg";

const useStyles = makeStyles(teamsStyle);

export default function MyBanglesTeam({ ...rest }) {
  const [prod, setProd] = React.useState([]);
  const classes = useStyles();

  const cardProfile1 = "https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880"
  const cardProfile2 = "https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880"
  const cardProfile4 = "https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880"

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response1 = await fetch(localApi + `/product/findById/501`);
        const json1 = await response1.json();

        const response2 = await fetch(localApi + `/product/findById/449`);
        const json2 = await response2.json();

        const response3 = await fetch(localApi + `/product/findById/500`);
        const json3 = await response3.json();

        // Create an empty array
        const productsArray = [];

        // Push json1, json2, json3 into the array
        productsArray.push(json1, json2, json3);

        // Output the array to check the result
        // console.log(productsArray);
        setProd(productsArray)


      } catch (error) {
        console.log("error", error);
      }
    };

    fetchProductData();
  }, []);

  return (
    <div className="cd-section" {...rest}>
      {/* Team 2 START */}
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>We offer an exclusive collection.</h2>
              <h5 className={classes.description}>
                Try the exclusive collection ever that makes you feel heavenly.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {prod && prod.map((p) => (
              <GridItem xs={12} sm={4} md={4} key={p.id}>
                <Card plain profile>
                  <CardHeader image plain>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={p.productImage[0].cropImageUrl +
                        "?id=" +
                        p.productImage[0].imgId} alt="..." 
                        // style={{width: 288, height: 336}} 
                        />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${p.productImage[0].cropImageUrl +
                          "?id=" +
                          p.productImage[0].imgId})`,
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>{p.productCategory.title +
                      " " +
                      p.productParent.title}{" "}
                      <br /> {p.itemCodeNumber}-{p.id}</h4>
                    <Muted>
                      <h6 className={classes.cardCategory}>{p.description}</h6>
                    </Muted>
                    <p className={classes.description + " " + classes.marginTop20}>{(p.readyGram * p.productSets.pcs).toFixed(4)}{" "}
                      Avg Gram Of {p.productSets.setDesc} {"and "}
                      {p.productBox.boxDesc}
                      {","} {p.cartDescription}
                      {"."}</p>
                    <p className={classes.description + " " + classes.marginTop20}>
                      {p.gstCash === true ? (
                        <>
                          <h5>
                            <span style={{ color: "red" }}>
                              Price ₹ {(p.taxInclude / p.productBox.box).toFixed(2) + " Inc."}
                            </span>
                          </h5>
                        </>
                      ) : (
                        <>
                          <h5>
                            <span style={{ color: "black" }}>
                              Price ₹ {(p.cashPrice / p.productBox.box).toFixed(2)}
                            </span>
                          </h5>
                        </>
                      )}
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            ))}

            {/* <GridItem xs={12} sm={4} md={4}>
              <Card plain profile>
                <CardHeader image plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={cardProfile2} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${cardProfile2})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>George West</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>BACKEND HACKER</h6>
                  </Muted>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  <Button justIcon round color="linkedin">
                    <i className="fab fa-linkedin-in" />
                  </Button>
                  <Button justIcon round color="facebook">
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <Button justIcon round color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain profile>
                <CardHeader image plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={cardProfile4} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${cardProfile4})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>Gina Andrew</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>PHOTOGRAPHER</h6>
                  </Muted>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  <Button justIcon round color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button>
                  <Button justIcon round color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem> */}
          </GridContainer>
        </div>
      </div>
      {/* Team 2 END */}
    </div>
  );
}
