import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";
import FaceMarc from "assets/img/faces/marc.jpg";
import FaceChristian from "assets/img/faces/christian.jpg";
import FaceAvatar from "assets/img/faces/avatar.jpg";
import FaceKendall from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Our Fabulous Team</h2>
          <h5 className={classes.description}>
            We believe success comes from Hard Work, Dedication, Team Work and
            discipline. Excellent teamwork from our Staff has brought
            improvement on all fronts.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img src={FaceMarc} alt="profile-pic" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Sharif H.Madna</h4>
              <h6 className={classes.textMuted}>CEO / Co-Founder</h6>
              <p className={classes.cardDescription}>
                Founder & Chief Executive Officer and a great team leader, have
                innovative ideas and a clear vision to rule the bangles market.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceKendall}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Asif Madna</h4>
              <h6 className={classes.textMuted}>PRODUCTION IN CHARGE</h6>
              <p className={classes.cardDescription}>
                Planning and organizing production schedules, assessing project
                and resource requirements
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceChristian}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Arif Madna</h4>
              <h6 className={classes.textMuted}>
                BANGLE DESIGNER AND QUALITY MANAGEMENT
              </h6>
              <p className={classes.cardDescription}>
                Hold consultations with clients, discuss a client's range of
                options formulate original ideas, and supervise the production
                process to make sure that all products meet consistent
                standards.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceChristian}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Shaik Toufiq</h4>
              <h6 className={classes.textMuted}>FULL STACK DEVELOPER</h6>
              <p className={classes.cardDescription}>
                Worked on developing different Pages and Components of this
                Website in an excellent manner.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceAvatar}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Monika Joshi</h4>
              <h6 className={classes.textMuted}>API DEVELOPER</h6>
              <p className={classes.cardDescription}>
                Developed Back-end and front-end API and helped in other
                difficult tasks
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceKendall}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Monika Joshi</h4>
              <h6 className={classes.textMuted}>ANDROID DEVELOPER</h6>
              <p className={classes.cardDescription}>
                Android Developer & Admin control.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceChristian}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Rubeena Ansari</h4>
              <h6 className={classes.textMuted}>CONTENT WRITER</h6>
              <p className={classes.cardDescription}>
                Writing Copies, Captions, Concepts & creating social media
                content.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img
                  src={FaceAvatar}
                  alt="profile-pic"
                  className={classes.img}
                />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Hemlata Chauhan </h4>
              <h6 className={classes.textMuted}>Accountant</h6>
              <p className={classes.cardDescription}>
                Helps businesses make critical financial decisions by
                collecting, tracking, and correcting the company{"'"}s finances.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
