import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Check from "@material-ui/icons/Check";
import { SiWhatsapp } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { Link } from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { localApi } from "utils/headApi";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-kit-pro-react/views/sweetAlretStyle";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

const useStyles = makeStyles(contactsStyle);
const useStyl = makeStyles(sweetAlertStyle);

// const RegularMap = () => {
//     const mapRef = React.useRef(null);
//     React.useEffect(() => {
//         let google = window.google;
//         let map = mapRef.current;
//         let lat = "44.43353";
//         let lng = "26.093928";
//         const myLatlng = new google.maps.LatLng(lat, lng);
//         const mapOptions = {
//             zoom: 14,
//             center: myLatlng,
//             scrollwheel: false,
//             zoomControl: true,
//             styles: [
//                 {
//                     featureType: "water",
//                     stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "geometry.fill",
//                     stylers: [
//                         { hue: "#ff0000" },
//                         { saturation: -100 },
//                         { lightness: 99 },
//                     ],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "geometry.stroke",
//                     stylers: [{ color: "#808080" }, { lightness: 54 }],
//                 },
//                 {
//                     featureType: "landscape.man_made",
//                     elementType: "geometry.fill",
//                     stylers: [{ color: "#ece2d9" }],
//                 },
//                 {
//                     featureType: "poi.park",
//                     elementType: "geometry.fill",
//                     stylers: [{ color: "#ccdca1" }],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "labels.text.fill",
//                     stylers: [{ color: "#767676" }],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "labels.text.stroke",
//                     stylers: [{ color: "#ffffff" }],
//                 },
//                 { featureType: "poi", stylers: [{ visibility: "off" }] },
//                 {
//                     featureType: "landscape.natural",
//                     elementType: "geometry.fill",
//                     stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
//                 },
//                 { featureType: "poi.park", stylers: [{ visibility: "on" }] },
//                 {
//                     featureType: "poi.sports_complex",
//                     stylers: [{ visibility: "on" }],
//                 },
//                 { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
//                 {
//                     featureType: "poi.business",
//                     stylers: [{ visibility: "simplified" }],
//                 },
//             ],
//         };

//         map = new google.maps.Map(map, mapOptions);

//         const marker = new google.maps.Marker({
//             position: myLatlng,
//             map: map,
//             animation: google.maps.Animation.DROP,
//             title: "Material Kit PRO React!",
//         });

//         const contentString =
//             '<div class="info-window-content"><h2>Material Kit PRO React</h2>' +
//             "<p>A premium Kit for React, Material-UI, and React Hooks.</p></div>";

//         const infowindow = new google.maps.InfoWindow({
//             content: contentString,
//         });

//         google.maps.event.addListener(marker, "click", function () {
//             infowindow.open(map, marker);
//         });
//     });
//     return (
//         <>
//             <div
//                 style={{ height: `100%`, borderRadius: "6px", overflow: "hidden" }}
//                 ref={mapRef}
//             ></div>
//         </>
//     );
// };

export default function MyBanglesContact({ ...rest }) {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const classes = useStyles();
  const classSweet = useStyl();

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleMobile = (e) => {
    setMobile(e.target.value);
    setMobileErr("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailErr("");
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setMessageErr("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter Your Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Phone Number");
    }
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Please Enter Email Address");
    }
    if (message === "" || !message) {
      errorCount++;
      setMessageErr("Please Enter Message");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: "2",
          firstName: firstName,
          mobile: mobile,
          email: email,
          websiteName: "mybangles24.com",
          message: message,
        }),
      };

      fetch(localApi + `/contactus/add`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 2 START */}
      <div className={classes.contacts2}>
        {/* <div className={classes.map}>
                    <RegularMap />
                </div> */}
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.card2}>
              <form>
                <CardHeader
                  contact
                  color="primary"
                  className={classes.textCenter}
                >
                  <h4 className={classes.cardTitle}>Contact Us</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <InfoArea
                        className={classes.infoArea2}
                        title="Call us at"
                        description={
                          <p>
                            <a href="tel:+91-2247501787">
                              <ImMobile />
                              +912247501787
                            </a>{" "}
                            <br />{" "}
                            <span>
                              <SiWhatsapp color="green" />{" "}
                              <a href="tel:+91-9967457786">+919967457786</a>
                            </span>
                            <br />
                            <Link
                              to="javascript:void(0)"
                              onClick={() =>
                                (window.location =
                                  "mailto:info@mybangles24.com")
                              }
                            >
                              <span>
                                <MdEmail /> info@mybangles24.com
                              </span>
                            </Link>{" "}
                            <br />
                            Monday – Saturday, <br />
                            10:00 AM to 06:00 PM <br />
                            Sunday <br /> 10:00 AM to 02:00 PM
                          </p>
                        }
                        icon={Phone}
                        iconColor="primary"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <InfoArea
                        className={classes.infoArea2}
                        title="Office Address"
                        description={
                          <p>
                            Shop no.20, Ashmita Jyoti CHS D10/11 bldg, <br />{" "}
                            Charkop Naka, Marve Road, <br /> Malad West
                            Mumbai-400095 - Maharashtra
                          </p>
                        }
                        icon={PinDrop}
                        iconColor="primary"
                      />
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="Your Name"
                    id="fristName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "fristName",
                      value: firstName,
                      onChange: (e) => handleFirstName(e),
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{firstNameErr}</span>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Email address"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "email",
                          value: email,
                          onChange: (e) => handleEmail(e),
                        }}
                        required={true}
                      />
                      <span style={{ color: "red" }}>{emailErr}</span>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Phone Number"
                        id="mobile"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "mobile",
                          value: mobile,
                          onChange: (e) => handleMobile(e),
                        }}
                        required={true}
                      />
                      <span style={{ color: "red" }}>{mobileErr}</span>
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="Your message"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 4,
                      name: "message",
                      value: message,
                      onChange: (e) => handleMessage(e),
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{messageErr}</span>
                </CardBody>
                <CardFooter className={classes.justifyContentBetween}>
                  <Button
                    color="primary"
                    className={classes.pullRight}
                    onClick={onSubmit}
                  >
                    Send Message
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <br />
      <br />
      {/* Contact us 2 END */}
    </div>
  );
}
