/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { headApi } from "utils/headApi";
import { localApi } from "utils/headApi";
// import { imgApi } from "utils/imgApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreditCardIcon from "@material-ui/icons/CreditCard";
// import Slide from "@material-ui/core/Slide";
// import Tooltip from "@material-ui/core/Tooltip";
// import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import Table from "components/Table/Tables.js";
import Tables from "components/Table/Table.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import MyHeader from "views/MyBangles/Section/MyHeader";
import MyHeaderLinks from "views/MyBangles/Section/MyHeaderLinks";
import MyFooter from "views/MyBangles/Section/MyFooter";

import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(profilePageStyle);
const useStylesTable = makeStyles(invoiceStyle);
const useStyleCart = makeStyles(listCart);
const useStyl = makeStyles(aboutUsStyle);

export default function MyOrder({ ...rest }) {
  const [addCart, setAddCart] = React.useState([]);
  // const [userId, setUserId] = React.useState("");
  const [load, setLoad] = React.useState(false);

  const classes = useStyles();
  const history = useHistory();

  const classesTable = useStylesTable();
  const classeCart = useStyleCart();
  const classeTitel = useStyl();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const current = new Date();
  const dateCurrent = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`;
  current.setDate(current.getDate() + 10);
  let dateEx = new Date(current).toLocaleDateString("de");

  async function getList() {
    const response = await fetch(localApi + `/clientGroup/allList`);
    const json = await response.json();
    const regClient = json.ClientList.filter((f) => f.regId == userObj.id)[0];
    // console.log(regClient);

    const getList = await fetch(
      localApi +
        `/orders/list/byAdminAndClientId/${userObj.admin.id}/${regClient.id}`
    );
    const res = await getList.json();
    console.log(res);
    if (res.error === "true") {
      setLoad(false);
      history.push("/error-page");
    } else {
      setLoad(true);
      // setAddCart(res.OrderData)
      setAddCart(res && res.length != 0 ? res : history.push("/error-page"));
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      <Helmet>
        <title>My Order</title>
      </Helmet>
      {load ? (
        <>
          <MyHeader
            color="transparent"
            brand="Home"
            links={<MyHeaderLinks dropdownHoverColor="primary" />}
            fixed
            changeColorOnScroll={{
              height: 300,
              color: "primary",
            }}
          />
          <Parallax
            image={require("assets/img/bg6.jpg").default}
            filter="primary"
            className={classes.parallax}
          >
            <div className={classeTitel.container}>
              <GridContainer justify="center">
                <GridItem
                  md={8}
                  sm={8}
                  className={classNames(
                    classeTitel.mlAuto,
                    classeTitel.mrAuto,
                    classeTitel.textCenter
                  )}
                >
                  <h1 className={classeTitel.title}>ORDER</h1>
                  <h4></h4>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classeCart.main, classeCart.mainRaised)}>
            <div className={classeCart.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {" "}
                  <h3 className={classeCart.cardTitle}>My Order</h3>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.mrAuto + " " + classes.mlAuto}
                >
                  <Tables
                    tableHead={[
                      <span className={classes.OrderlistDescription}>SI.No</span>,
                      <span className={classes.OrderlistDescription}>Order Id</span>,
                      <span className={classes.OrderlistDescription}>Date</span>,
                      <span className={classes.OrderlistDescription}>Units</span>,
                      <span className={classes.OrderlistDescription}>Amount</span>,
                      <span className={classes.OrderlistDescription}>Balance</span>,
                      <span className={classes.OrderlistDescription}>Status</span>,
                      <span className={classes.OrderlistDescription}>View</span>,
                      <span className={classes.OrderlistDescription}>Payment</span>,
                    ]}
                    tableData={addCart.map((c, index) => [
                      <span className={classes.OrderlistDescription}>{index + 1}</span>,
                      <span className={classes.OrderlistDescription}>{c.id}</span>,
                      <span className={classes.OrderlistDescription}>{c.createDate.slice(0, -19)}</span>,
                      <span className={classes.OrderlistDescription}>{c.totalBox}</span>,
                      <span className={classes.OrderlistDescription}>₹ {Math.round(c.amount) + "/-"}</span>,
                      <span className={classes.OrderlistDescription}>₹ {Math.round(c.balance) + "/-"}</span>,
                      <span className={classes.OrderlistDescription}>
                        {(
                          (c.payment.reduce((a, b) => a + b.received, 0) /
                            c.amount) *
                          100
                        ).toFixed(2)}
                        %
                      </span>,
                      <a href={`/order-view?id=${c.id}&client=${c.clientId}`}>
                        <VisibilityIcon />
                      </a>,
                      <a
                        href={`/payment-recieved?id=${c.id}&client=${c.clientId}`}
                      >
                        <CreditCardIcon />
                      </a>,
                    ])}
                  />
                  <br />
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <MyFooter />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
