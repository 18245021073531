/* eslint-disable */
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import { localApi } from "utils/headApi";

import myBanglesProject from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

const useStyles = makeStyles(myBanglesProject);

export default function MyBanglesProject({ ...rest }) {
    const [prod, setProd] = React.useState([]);
    const classes = useStyles();

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const response1 = await fetch(localApi + `/product/findById/498`);
                const json1 = await response1.json();

                const response2 = await fetch(localApi + `/product/findById/499`);
                const json2 = await response2.json();

                const response3 = await fetch(localApi + `/product/findById/448`);
                const json3 = await response3.json();

                // Create an empty array
                const productsArray = [];

                // Push json1, json2, json3 into the array
                productsArray.push(json1, json2, json3);

                // Output the array to check the result
                // console.log(productsArray);
                setProd(productsArray)


            } catch (error) {
                console.log("error", error);
            }
        };

        fetchProductData();
    }, []);

    return (
        <div className="cd-section" {...rest}>
            {/* Project 2 START */}
            <div className={classes.projects}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >
                            <Muted>
                                <h6>OUR WORK</h6>
                            </Muted>
                            <h2 className={classes.title}>
                                Some of Our Awesome Products - 2
                            </h2>
                            <h5 className={classes.description}>
                                Quality is what we focus on, Come if you want something better.
                            </h5>
                            <div className={classes.sectionSpace} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        {prod && prod.map((p) => (
                            <GridItem xs={12} sm={4} md={4} key={p.id}>
                                <Card plain className={classes.card2}>
                                    <a
                                        href={`/bangles-product?id=${p.id}&catdesc=${p.productCategory.title}&cat1desc=${p.productParent.title}`}
                                    // target="_blank"
                                    >
                                        <CardHeader image plain>
                                            <img
                                                src={
                                                    p.productImage[0].cropImageUrl +
                                                    "?id=" +
                                                    p.productImage[0].imgId
                                                }
                                                alt="..." 
                                                // style={{width: 288, height: 336}}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage:
                                                        `url(${p.productImage[0].cropImageUrl +
                                                        "?id=" +
                                                        p.productImage[0].imgId})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </a>
                                    <CardBody plain>
                                        <a
                                            href={`/bangles-product?id=${p.id}&catdesc=${p.productCategory.title}&cat1desc=${p.productParent.title}`}
                                        // target="_blank"
                                        >
                                            <h4 className={classes.cardTitle}>
                                                {p.productCategory.title +
                                                    " " +
                                                    p.productParent.title}{" "}
                                                <br /> {p.itemCodeNumber}-{p.id}
                                            </h4>
                                        </a>
                                        <h6 className={classes.description}>{p.description}</h6>
                                        <p
                                            className={classes.description + " " + classes.marginTop20}
                                        >
                                            {(p.readyGram * p.productSets.pcs).toFixed(4)}{" "}
                                            Avg Gram Of {p.productSets.setDesc} {"and "}
                                            {p.productBox.boxDesc}
                                            {","} {p.cartDescription}
                                            {"."}
                                        </p>
                                        <p className={classes.description + " " + classes.marginTop20}>
                                            {p.gstCash === true ? (
                                                <>
                                                    <h5>
                                                        <span style={{ color: "red" }}>
                                                            Price ₹ {(p.taxInclude / p.productBox.box).toFixed(2) + " Inc."}
                                                        </span>
                                                    </h5>
                                                </>
                                            ) : (
                                                <>
                                                    <h5>
                                                        <span style={{ color: "black" }}>
                                                            Price ₹ {(p.cashPrice / p.productBox.box).toFixed(2)}
                                                        </span>
                                                    </h5>
                                                </>
                                            )}
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        ))}
                        {/* <GridItem xs={12} sm={4} md={4}>
                            <Card plain className={classes.card2}>
                                <a
                                    href="https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                    target="_blank"
                                >
                                    <CardHeader image plain>
                                        <img
                                            src="https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880"
                                            alt="..."
                                        />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage:
                                                    "url('https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880')",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                </a>
                                <CardBody plain>
                                    <a
                                        href="https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                        target="_blank"
                                    >
                                        <h4 className={classes.cardTitle}>
                                            Light Bootstrap Dashboard PRO React
                                        </h4>
                                    </a>
                                    <h6 className={classes.description}>Premium Template</h6>
                                    <p
                                        className={classes.description + " " + classes.marginTop20}
                                    >
                                        Light Bootstrap Dashboard PRO is a Bootstrap 4 Admin Theme
                                        designed to look simple and beautiful. Forget about boring
                                        dashboards and grab yourself a copy to kickstart new
                                        project!
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card plain className={classes.card2}>
                                <a
                                    href="https://www.creative-tim.com/product/now-ui-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                    target="_blank"
                                >
                                    <CardHeader image plain>
                                        <img
                                            src="https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880"
                                            alt="..."
                                        />
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                                backgroundImage:
                                                    "url('https://api.inshabangles.com/api/image/cropImageDownload/20190813_173846.jpg?id=880')",
                                                opacity: "1",
                                            }}
                                        />
                                    </CardHeader>
                                </a>
                                <CardBody plain>
                                    <a
                                        href="https://www.creative-tim.com/product/now-ui-dashboard-pro-react?ref=mkpr-projects-section-sections"
                                        target="_blank"
                                    >
                                        <h4 className={classes.cardTitle}>
                                            Now UI Dashboard PRO React
                                        </h4>
                                    </a>
                                    <h6 className={classes.description}>Premium Template</h6>
                                    <p
                                        className={classes.description + " " + classes.marginTop20}
                                    >
                                        Now UI Dashboard React is an admin dashboard template
                                        designed by Invision and coded by Creative Tim. It is built
                                        on top of Reactstrap, using Now UI Dashboard and it is fully
                                        responsive.
                                    </p>
                                </CardBody>
                            </Card>
                        </GridItem> */}
                    </GridContainer>
                </div>
            </div>
            {/* Project 2 END */}
        </div>
    );
}
