/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LicencesDescription from "views/MyBangles/Section/LicencesDescription";
import MyHeader from "views/MyBangles/Section/MyHeader";
import MyHeaderLinks from "views/MyBangles/Section/MyHeaderLinks";
import MyFooter from "views/MyBangles/Section/MyFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);

export default function Licences() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
        <Helmet>
        <title>Licences</title>
        <meta
          name="description"
          content="Insha Bangles Licences"
        />
        <meta name="keywords" content="Insha Bangles Licences" />
      </Helmet>
      <MyHeader
        brand="Home"
        links={<MyHeaderLinks dropdownHoverColor="primary" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "primary",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        // style={{backgrounColor: "#3c4858"}}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 style={{color: "#fff", fontFamily: "Tahoma", fontWeight: "bold"}}>License</h1>
              <h4 style={{color: "#fff"}}>
                
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <LicencesDescription />
          <br />
        </div>
      </div>
      <MyFooter />
    </div>
  );
}