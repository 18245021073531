import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function TermsDescription() {
    const classes = useStyles();
    return (
        <div className={classNames(classes.aboutDescription)}>
            <GridContainer>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Terms and Conditions</h3>
                    <h5 className={classes.description}>
                        Welcome to My bangles 24!<br />
                        These terms and conditions outline the rules and regulations for the use of Insha bangles{"'"}s Website, located at <a href="https://mybangles24.com" target={"_blank"} rel="noreferrer">mybangles24.com</a><br />
                        Please read these Terms and Conditions carefully before using the <a href="https://mybangles24.com" target={"_blank"} rel="noreferrer">mybangles24.com</a> website. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>The use of this website is subject to the following terms of use:</h3>
                    <ul>
                        <li className={classes.description}>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                        <li className={classes.description}>We offer a lifetime return policy on all purchases within India. The product along with the Bill can be returned on its current market value. We will give the fresh lot of bangles in return for the products with deductions towards making charges and processing charges.</li>
                        <li className={classes.description}>You are liable to get the full refund only after the returns of goods within 7 days of purchase, in case of goods received of different quality, different design or different size</li>
                        <li className={classes.description}>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                        <li className={classes.description}>In case you get a different size and design, then we will take that material and make another product for you within 25 working days.</li>
                        <li className={classes.description}>All trademarks reproduced in this website that are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                        <li className={classes.description}>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
                        <li className={classes.description}>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                        <li className={classes.description}>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authorities.</li>
                        <li className={classes.description}>The product weight mentioned on the website for Bangles and amounts may vary upon manufacture of the product. In case, the customer receives a product of lesser product weight than the value paid for, the company will refund such differential amount to the customer. Similarly, in case the product is of a higher weight than the value paid for, an additional cost will be applicable for the same to the buyer. The difference in weight will be disclosed only after the final product is ready.</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Information on our Site</h3>
                    <h5 className={classes.description}>
                        At Jewelry Designs, we make every effort to ensure that our online catalog is as accurate and complete as possible. In order to give you the opportunity to view our pieces in full detail, some pieces may appear larger or smaller than their actual size in our photographs; and since every computer monitor is set differently, size may vary slightly. On our site, we provide the measurement of each piece based on our manufacturing specifications
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>ADVANCE/PART PAYMENT</h3>
                    <h5 className={classes.description}>
                    <a href="https://mybangles24.com" target={"_blank"} rel="noreferrer">mybangles24.com</a>  may, at its sole discretion, request the user to pay a percentage of the value of the transaction as an advance at the time of placing the order. The balance payment for the order shall be paid by the user at the time of delivery of the product. Failure to pay the balance amount at the time of the delivery of the product shall be treated as a cancellation of the order. Any advance amount paid by the user at the time of placing the order shall not be refunded by <a href="https://mybangles24.com" target={"_blank"} rel="noreferrer">mybangles24.com</a>  to the user in the event of cancellation of the order for any reason whatsoever.
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>GST</h3>
                    <h5 className={classes.description}>
                        GST in India is 3%, and our prices are exclusive of taxes.
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Links To Other WebSites</h3>
                    <h5 className={classes.description}>
                        Our Service may contain links to third-party websites or services that are not owned or controlled by Insha. Insha has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Insha shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Product Availability</h3>
                    <h5 className={classes.description}>
                    All of our bangles are designed and manufactured on-site in Mumbai, Rajkot, and Basavakalyan. If you are interested in a piece that is currently on backorder, please call us and we can tell you when the item will be available. Because this jewelry is made here in our facility, there may be a 1-4 week delay before the piece can be shipped.
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>WARRANTY</h3>
                    <h5 className={classes.description}>
                        Currently, we provide a 6-month warranty on  our Products<br />
                        We carry out thorough quality checks before processing the ordered item. We take utmost care while packing the product. At the same time we ensure that the packing is good such that the items won{"'"}t get damaged during transit
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Contact Us</h3>
                    <h5 className={classes.description}>
                        If you have any questions about these Terms, please <a href="https://mybangles24.com/contact-us" target={"_blank"} rel="noreferrer">contact us</a>.
                    </h5>
                </GridItem>
            </GridContainer>
            <br />
        </div>
    );
}