/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import { Helmet } from "react-helmet";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Carousel from "views/MyBangles/Carousel"

import MyHeader from "views/MyBangles/Section/MyHeader";
import MyHeaderLinks from "views/MyBangles/Section/MyHeaderLinks";
import MyFooter from "views/MyBangles/Section/MyFooter";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import MyBanglesProject from "./Section/MyBanglesProject.js"
import MyBanglesTeam from "./Section/MyBanglesTeam.js"
import MyBanglesContact from "./Section/MyBanglesContact.js"
import MyBanglesCard from "./Section/MyBanglesCard.js"

const useStyles = makeStyles(landingPageStyle);

export default function MyBanglesPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  // const activeSession = sessionStorage.getItem("sessionData");

  return (
    <div>
      <Helmet>
        <title>Mybangles24</title>
      </Helmet>
      <MyHeader
        color="transparent"
        brand="Home"
        links={<MyHeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "primary",
        }}
        {...rest}
      />
      <Carousel />
      {/* <Parallax image={require("assets/img/bg8.jpg").default} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>My Bangles 24</h1>
              <h4>
              We Are One Of The Top Manufacturers And Retailers In India, Having Hundreds Of Satisfied And Happy Clients From India And Around The Globe. We Manufacture Bangles Of Different Models, Sizes, And Colors Like Micro Gold Plated, Rose Gold Plated, Silver Plated, 2 Tone & 3 Tone 7 Seminario Bangles At Very Affordable Low Prices.
              </h4>
              <br />
              {activeSession ? "" :
                <div>
                  <Button
                    color="primary"
                    // size="lg"
                    href="/sign-up"
                  >
                    sign up
                  </Button>
                  {" "}
                  <Button
                    color="primary"
                    // size="lg"
                    href="/login"
                  >
                    log in
                  </Button>
                </div>
              }
            </GridItem>
          </GridContainer>
        </div>
      </Parallax> */}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <MyBanglesProject />
        </div>
      </div>
      {/* <MyBanglesCard /> */}
      <MyBanglesTeam />
      <MyBanglesContact />
      <MyFooter />
    </div>
  );
}
