/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import MyHeader from "views/MyBangles/Section/MyHeader";
import MyHeaderLinks from "views/MyBangles/Section/MyHeaderLinks";
import MyFooter from "views/MyBangles/Section/MyFooter";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";

import logo from "assets/img/examples/apple-bangles-icon.png";
import kada1 from "assets/img/faces/kada1.jpg";
import polaBangles2 from "assets/img/faces/polaBangles2.jpg";
import fourPieces3 from "assets/img/faces/fourPieces3.jpg";
import sixPieces4 from "assets/img/faces/sixPieces4.jpg";
import sevenPieces5 from "assets/img/faces/sevenPieces5.jpg";
import eightPieces6 from "assets/img/faces/eightPieces6.jpg";
import twevelPieces7 from "assets/img/faces/twevelPieces7.jpg";
import twentyFourPieces8 from "assets/img/faces/twentyFourPieces8.jpg";
import axios from "axios";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MyProductList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(1);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();
  const history = useHistory();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // const headApi = `https://api.bangles.org.in/api/rest`;
  // const imgApi = `https://imgstore.bangles.org.in`;

  const fetchData = async () => {
    axios
      .get(
        localApi + `/product/list/byAdminId/${userObj ? userObj.admin.id : "2"}`
      )
      .then((res) => {
        const pStock = res.data.filter((f) => f.stockSize.length === 1);
        // console.log(pStock);
        setProdList(pStock);
      });
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Product Stock</title>
        <meta
          name="description"
          content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)."
        />
        <meta
          name="keywords"
          content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs"
        />
      </Helmet>
      <MyHeader
        color="transparent"
        brand="Home"
        links={<MyHeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "primary",
        }}
      />
      <Parallax
        // image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Product Stock</h1>
              <h4 style={{ color: "white" }}>
                Here you will get to see our elegant collection of different
                designs, sizes and colors of bangles. These are our overall
                products which you can buy online as well as offline.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.container}>
        <h5 className={classNames(classes.title, classes.textCenter)}>
          We make bangles that are loved by all age group ladies because our
          designs are unique and never get out of fashion. The raw material we
          use is of fine quality and also we give 6 months colour guarantee on
          our bangles. As we are a manufacturer, we give bangles at factory
          prices (discounted rates). We give an opportunity to customize the
          bangles according to your choice and taste so that you feel the joy of
          wearing the best bangles.
        </h5>
      </div>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "#9c27b0" }}
                  />
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={kada1} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${kada1})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={polaBangles2} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${polaBangles2})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={fourPieces3} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${fourPieces3})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={sixPieces4} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${sixPieces4})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={sevenPieces5} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${sevenPieces5})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={eightPieces6} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${eightPieces6})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={twevelPieces7} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${twevelPieces7})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={twentyFourPieces8} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${twentyFourPieces8})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              Popular items Today:
            </h3>

            {/* <InfiniteScroll
                            dataLength={prodList.length}
                            next={fetchData}
                            hasMore={true}
                        > */}
            <div className={classes.container}>
              <GridContainer>
                {prodList &&
                  prodList.map((prod) => (
                    <GridItem sm={6} md={3} key={prod.id}>
                      <Card product>
                        <CardHeader image>
                          {/* <CornerRibbon
                                                        position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                                                        fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                                                        backgroundColor="#9c27b0" // OPTIONAL, default as "#2c7"
                                                        containerStyle={{}} // OPTIONAL, style of the ribbon
                                                        style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                                                        className="" // OPTIONAL, css class of ribbon
                                                    > {prod.total > 0 ? "In Stock" : "Make To Order"}
                                                    </CornerRibbon> */}
                          <p
                            style={{
                              position: "absolute",
                              left: "90%",
                              top: "90%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <Tooltip
                              id="tooltip-tops"
                              title="Share to WhatsApp"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <a
                                                                href={`https://api.whatsapp.com/send/?phone=919967457786&text=https://mybangles24.com/bangles-product?id=${prod.id}%20${prod.productCategory.title}%20${prod.productParent.title}&app_absent=0`}
                              >
                                {prod.productImage[0].cropImageUrl +
                                "?id=" +
                                prod.productImage[0].imgId ? (
                                  <TiArrowForward size={50} color="#00acc1" />
                                ) : (
                                  ""
                                )}
                                {/* {imgApi + "" + prod.design1.image1 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                imgApi + "" + prod.design2.image2 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                    imgApi + "" + prod.design3.image3 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                        imgApi + "" + prod.design4.image4 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                            imgApi + "" + prod.design5.image5 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> :
                                                                                imgApi + "" + prod.design6.image6 !== imgApi + "" + "/crop_img/null" ? <TiArrowForward size={50} color="#00acc1" /> : ""} */}
                              </a>
                            </Tooltip>
                          </p>
                          <img
                            src={
                              prod.productImage[0].cropImageUrl +
                              "?id=" +
                              prod.productImage[0].imgId
                            }
                            alt="..."
                          />
                          {/* {prod.design1.img1active === 1 ? <img src={imgApi + "" + prod.design1.image1} alt="..." /> :
                                                        prod.design2.img2active === 1 ? <img src={imgApi + "" + prod.design2.image2} alt="..." /> :
                                                            prod.design3.img3active === 1 ? <img src={imgApi + "" + prod.design3.image3} alt="..." /> :
                                                                prod.design4.img4active === 1 ? <img src={imgApi + "" + prod.design4.image4} alt="..." /> :
                                                                    prod.design5.img5active === 1 ? <img src={imgApi + "" + prod.design5.image5} alt="..." /> :
                                                                        prod.design6.img6active === 1 ? <img src={imgApi + "" + prod.design6.image6} alt="..." /> : ""} */}
                          <p
                            style={{
                              position: "absolute",
                              top: "65%",
                              left: "50%",
                              width: "65px",
                              height: "65px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            {/* <img src={logo} alt="..." />  */}
                            <img src={logo} alt="..." />
                            {/* {imgApi + "" + prod.design1.image1 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                            imgApi + "" + prod.design2.image2 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                imgApi + "" + prod.design3.image3 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                    imgApi + "" + prod.design4.image4 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                        imgApi + "" + prod.design5.image5 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> :
                                                                            imgApi + "" + prod.design6.image6 !== imgApi + "" + "/crop_img/null" ? <img src={logo} alt="..." /> : ""} */}
                          </p>
                        </CardHeader>
                        <CardBody>
                          <h6
                            className={classNames(
                              classes.cardCategory,
                              classes.textRose
                            )}
                          >
                            {prod.productCategory.title} <br />{" "}
                            {prod.productParent.title} {prod.itemcode}-{prod.id}
                          </h6>
                          <h4 className={classes.cardTitle}>
                            {prod.description}
                          </h4>
                          <div className={classes.cardDescription}>
                            {(prod.readyGram * prod.productSets.pcs).toFixed(4)}{" "}
                            Avg Gram Of {prod.productSets.setDesc} {"and "}
                            {prod.productBox.boxDesc}
                            {","} {prod.cartDescription}
                            {"."}
                          </div>
                          <GridContainer className={classes.sizePadding}>
                            {prod.stockSize[0].s1x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x8 == null
                                  ? ""
                                  : prod.stockSize[0].s1x8 == 0
                                  ? ""
                                  : "1x8"}
                                <br />
                                {prod.stockSize[0].s1x8 == null
                                  ? ""
                                  : prod.stockSize[0].s1x8 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x10 == null
                                  ? ""
                                  : prod.stockSize[0].s1x10 == 0
                                  ? ""
                                  : "1x10"}
                                <br />
                                {prod.stockSize[0].s1x10 == null
                                  ? ""
                                  : prod.stockSize[0].s1x10 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x12 == null
                                  ? ""
                                  : prod.stockSize[0].s1x12 == 0
                                  ? ""
                                  : "1x12"}
                                <br />
                                {prod.stockSize[0].s1x12 == null
                                  ? ""
                                  : prod.stockSize[0].s1x12 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x14 == null
                                  ? ""
                                  : prod.stockSize[0].s1x14 == 0
                                  ? ""
                                  : "1x14"}
                                <br />
                                {prod.stockSize[0].s1x14 == null
                                  ? ""
                                  : prod.stockSize[0].s1x14 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x0 == null
                                  ? ""
                                  : prod.stockSize[0].s2x0 == 0
                                  ? ""
                                  : "2x0"}
                                <br />
                                {prod.stockSize[0].s2x0 == null
                                  ? ""
                                  : prod.stockSize[0].s2x0 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x0}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x2 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x2 == null
                                  ? ""
                                  : prod.stockSize[0].s2x2 == 0
                                  ? ""
                                  : "2x2"}
                                <br />
                                {prod.stockSize[0].s2x2 == null
                                  ? ""
                                  : prod.stockSize[0].s2x2 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x2}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x4 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x4 == null
                                  ? ""
                                  : prod.stockSize[0].s2x4 == 0
                                  ? ""
                                  : "2x4"}
                                <br />
                                {prod.stockSize[0].s2x4 == null
                                  ? ""
                                  : prod.stockSize[0].s2x4 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x4}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x6 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x6 == null
                                  ? ""
                                  : prod.stockSize[0].s2x6 == 0
                                  ? ""
                                  : "2x6"}
                                <br />
                                {prod.stockSize[0].s2x6 == null
                                  ? ""
                                  : prod.stockSize[0].s2x6 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x6}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x8 == null
                                  ? ""
                                  : prod.stockSize[0].s2x8 == 0
                                  ? ""
                                  : "2x8"}
                                <br />
                                {prod.stockSize[0].s2x8 == null
                                  ? ""
                                  : prod.stockSize[0].s2x8 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x10 == null
                                  ? ""
                                  : prod.stockSize[0].s2x10 == 0
                                  ? ""
                                  : "2x10"}
                                <br />
                                {prod.stockSize[0].s2x10 == null
                                  ? ""
                                  : prod.stockSize[0].s2x10 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x12 == null
                                  ? ""
                                  : prod.stockSize[0].s2x12 == 0
                                  ? ""
                                  : "2x12"}
                                <br />
                                {prod.stockSize[0].s2x12 == null
                                  ? ""
                                  : prod.stockSize[0].s2x12 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x14 == null
                                  ? ""
                                  : prod.stockSize[0].s2x14 == 0
                                  ? ""
                                  : "2x14"}
                                <br />
                                {prod.stockSize[0].s2x14 == null
                                  ? ""
                                  : prod.stockSize[0].s2x14 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s3x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s3x0 == null
                                  ? ""
                                  : prod.stockSize[0].s3x0 == 0
                                  ? ""
                                  : "3x0"}
                                <br />
                                {prod.stockSize[0].s3x0 == null
                                  ? ""
                                  : prod.stockSize[0].s3x0 == 0
                                  ? ""
                                  : prod.stockSize[0].s3x0}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          <GridContainer className={classes.sizePadding}>
                            {prod.stockSize[0].s1x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x9 == null
                                  ? ""
                                  : prod.stockSize[0].s1x9 == 0
                                  ? ""
                                  : "1x9"}
                                <br />
                                {prod.stockSize[0].s1x9 == null
                                  ? ""
                                  : prod.stockSize[0].s1x9 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x11 == null
                                  ? ""
                                  : prod.stockSize[0].s1x11 == 0
                                  ? ""
                                  : "1x11"}
                                <br />
                                {prod.stockSize[0].s1x11 == null
                                  ? ""
                                  : prod.stockSize[0].s1x11 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x13 == null
                                  ? ""
                                  : prod.stockSize[0].s1x13 == 0
                                  ? ""
                                  : "1x13"}
                                <br />
                                {prod.stockSize[0].s1x13 == null
                                  ? ""
                                  : prod.stockSize[0].s1x13 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s1x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s1x15 == null
                                  ? ""
                                  : prod.stockSize[0].s1x15 == 0
                                  ? ""
                                  : "1x15"}
                                <br />
                                {prod.stockSize[0].s1x15 == null
                                  ? ""
                                  : prod.stockSize[0].s1x15 == 0
                                  ? ""
                                  : prod.stockSize[0].s1x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x1 == null
                                  ? ""
                                  : prod.stockSize[0].s2x1 == 0
                                  ? ""
                                  : "2x1"}
                                <br />
                                {prod.stockSize[0].s2x1 == null
                                  ? ""
                                  : prod.stockSize[0].s2x1 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x1}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x3 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x3 == null
                                  ? ""
                                  : prod.stockSize[0].s2x3 == 0
                                  ? ""
                                  : "2x3"}
                                <br />
                                {prod.stockSize[0].s2x3 == null
                                  ? ""
                                  : prod.stockSize[0].s2x3 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x3}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x5 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x5 == null
                                  ? ""
                                  : prod.stockSize[0].s2x5 == 0
                                  ? ""
                                  : "2x5"}
                                <br />
                                {prod.stockSize[0].s2x5 == null
                                  ? ""
                                  : prod.stockSize[0].s2x5 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x5}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x7 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x7 == null
                                  ? ""
                                  : prod.stockSize[0].s2x7 == 0
                                  ? ""
                                  : "2x7"}
                                <br />
                                {prod.stockSize[0].s2x7 == null
                                  ? ""
                                  : prod.stockSize[0].s2x7 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x7}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x9 == null
                                  ? ""
                                  : prod.stockSize[0].s2x9 == 0
                                  ? ""
                                  : "2x9"}
                                <br />
                                {prod.stockSize[0].s2x9 == null
                                  ? ""
                                  : prod.stockSize[0].s2x9 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x11 == null
                                  ? ""
                                  : prod.stockSize[0].s2x11 == 0
                                  ? ""
                                  : "2x11"}
                                <br />
                                {prod.stockSize[0].s2x11 == null
                                  ? ""
                                  : prod.stockSize[0].s2x11 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x13 == null
                                  ? ""
                                  : prod.stockSize[0].s2x13 == 0
                                  ? ""
                                  : "2x13"}
                                <br />
                                {prod.stockSize[0].s2x13 == null
                                  ? ""
                                  : prod.stockSize[0].s2x13 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s2x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s2x15 == null
                                  ? ""
                                  : prod.stockSize[0].s2x15 == 0
                                  ? ""
                                  : "2x15"}
                                <br />
                                {prod.stockSize[0].s2x15 == null
                                  ? ""
                                  : prod.stockSize[0].s2x15 == 0
                                  ? ""
                                  : prod.stockSize[0].s2x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {prod.stockSize[0].s3x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {prod.stockSize[0].s3x1 == null
                                  ? ""
                                  : prod.stockSize[0].s3x1 == 0
                                  ? ""
                                  : "3x1"}
                                <br />
                                {prod.stockSize[0].s3x1 == null
                                  ? ""
                                  : prod.stockSize[0].s3x1 == 0
                                  ? ""
                                  : prod.stockSize[0].s3x1}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                        </CardBody>
                        <CardFooter className={classes.justifyContentBetween}>
                          <div className={classes.price}>
                            {prod.gstCash === true ? (
                              <>
                                <h5>
                                  <span style={{ color: "red" }}>
                                    Price ₹{" "}
                                    {prod.taxInclude.toFixed(2) + " Inc."}
                                  </span>
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5>
                                  <span style={{ color: "black" }}>
                                    Price ₹ {prod.cashPrice.toFixed(2)}
                                  </span>
                                </h5>
                              </>
                            )}
                            {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5><span style={{ color: "red" }}>Price ₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>} */}

                            {/* {!activeSession ? prod.taxCalculation.profitactive === 1 ? prod.taxCalculation.cash_gst === 1 ?
                                                            <><h5><span style={{ color: "red" }}>Price ₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                            </h6> </> :
                                                            <h5>Price <span>₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</span></h5> :
                                                            prod.taxCalculation.cash_gst === 1 ?
                                                                <><h5><span style={{ color: "#9c27b0" }}>Price ₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                                </h6></> :
                                                                <h5>Price <span>₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</span></h5> : ""}

                                                        {activeSession ? prod.taxCalculation.profitactive === 1 ? <h6><span style={{ textDecoration: "line-through", color: "red" }}>Price ₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                            <h6><span style={{ textDecoration: "line-through", color: "#9c27b0" }}>Price ₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> : ""} */}
                          </div>
                          <div className={classes.stats}>
                            <Tooltip
                              id="tooltip-top"
                              title="Save to Wishlist"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button justIcon color="rose" simple>
                                <Favorite />
                              </Button>
                            </Tooltip>
                          </div>
                        </CardFooter>
                        {/* <CardFooter className={classes.justifyContentBetween}>
                                            <div className={classes.price}>
                                                <Button color="info" href={`/bangles-product?id=${prod.id}&catdesc=${prod.cat.description}&cat1desc=${prod.banglessizemm.description}&Plating=${prod.plating.description.slice(8)}`}>
                                                // <Subject />
                                                Buy Now
                                                </Button>
                                    
                                            </div>
                                            <div>
                                                <Button color="success" round justIcon href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp}&text=https://bangles.org.in/bangles-product?id=${prod.id}%20${prod.banglessizemm.description}%20${prod.plating.description.slice(8)}&app_absent=0`}>
                                                    <i className="fab fa-whatsapp" style={{ fontSize: '30px' }} />
                                                </Button>
                                            </div>
                                        </CardFooter> */}
                        <CardFooter className={classes.justifyContentBetween}>
                          <div className={classes.price}>
                            <Button
                              color="primary"
                              href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                            >
                              Buy Now
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))}
              </GridContainer>
            </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>
      </div>
      <MyFooter />
    </div>
  );
}
