/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { ToWords } from "to-words";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import SweetAlert from "react-bootstrap-sweetalert";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import MyHeader from "views/MyBangles/Section/MyHeader";
import MyHeaderLinks from "views/MyBangles/Section/MyHeaderLinks";
import MyFooter from "views/MyBangles/Section/MyFooter";

import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(profilePageStyle);
const useStylesTable = makeStyles(invoiceStyle);
const useStyleCart = makeStyles(listCart);
const useStyleAlert = makeStyles(style);
const useStyle = makeStyles(aboutUsStyle);

export default function OredrView({ ...rest }) {
  const [addCart, setAddCart] = React.useState([]);
  // const [userId, setUserId] = React.useState("");
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [termsClient, setTermsClient] = React.useState([]);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [cartId, setCartId] = React.useState();
  const [load, setLoad] = React.useState(false);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);
  const [ordList, setOrdList] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [cartLength, setCartLength] = React.useState("");

  const [invoiceDate, setInvoiceDate] = React.useState("");

  const [uniPer, setUniPer] = React.useState([]);
  const [newTaxable, setNewTaxable] = React.useState([]);
  const [newIgst, setNewIgst] = React.useState([]);
  const [newCgst, setNewCgst] = React.useState([]);
  const [newSgst, setNewSgst] = React.useState([]);
  const [newTotalTax, setNewTotalTax] = React.useState([]);
  const [finalAmt, setFinalAmt] = React.useState();

  const classes = useStyles();
  const history = useHistory();

  const classesTable = useStylesTable();
  const classeCart = useStyleCart();
  const classeAlert = useStyleAlert();
  const classe = useStyle();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const client = new URLSearchParams(search).get("client");

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const current = new Date();
  const dateCurrent = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`;
  current.setDate(current.getDate() + 10);
  let dateEx = new Date(current).toLocaleDateString("de");

  // const itemz = itemProd.find((itemz) => itemz.id == value);

  const termsDefault = [
    {
      id: 1,
      discription: "Goods once sold will not be taken back.",
      type: "Tax Invoice",
    },
    {
      id: 2,
      discription:
        "Interest @ 18% p.a. will be charged if the payment is not made within the stipulated time.",
      type: "Tax Invoice",
    },
    {
      id: 3,
      discription: "Subject to 'Maharashtra' Jurisdiction only.",
      type: "Tax Invoice",
    },
  ];

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(
      res && res.filter((a) => a.addressType === "GSTIN ADDRESS")
    );
    // console.log(res);
  }

  async function getOrderList() {
    const getOrder =
      localApi + `/orders/list/byAdminId/${userObj.admin.id}/${client}/${id}`;
    const resOrder = await fetch(getOrder);
    const resultOrder = await resOrder.json();
    // console.log(resultOrder);
    setOrdList(resultOrder && resultOrder);
    // console.log(resultOrder.dateOfInvoice);
    setInvoiceDate(resultOrder.dateOfInvoice);
    setRegAddress1(resultOrder.gstAddress);
    setRegAddress2(resultOrder.shippingAddress);
    setBranch(resultOrder.transbranch);

    const multiCartId = resultOrder.ordersCart.map((i) => {
      return i.cartId;
    });

    const getCart =
      localApi + `/cart/list/byAdminId/${userObj && userObj.admin.id}`;
    const resCart = await fetch(getCart);
    const resultCart = await resCart.json();
    const ordersCart = resultCart.filter(
      (f) => f.active === true && multiCartId.includes(f.id)
    );

    setCartLength(multiCartId.length);
    setAddCart(ordersCart);

    // var converter = require("number-to-words");
    const fAmount = Math.round(
      ordersCart &&
        ordersCart.reduce((a, b) => a + b.amount + b.cartPackCharge, 0)
    );

    const toWords = new ToWords();
    let words = toWords.convert(fAmount);
    // console.log(words);
    setFinalAmt(words && words);

    const tax = ordersCart && ordersCart.map((m) => m.taxsumary);
    const taaxId = tax.map((m) => {
      return m.taxId;
    });
    // console.log(tax);

    const uniquePer = [];
    tax &&
      tax.map((x) =>
        uniquePer.filter((a) => a.taxType == x.taxType).length > 0
          ? null
          : uniquePer.push(x)
      );
    setUniPer(uniquePer && uniquePer);

    const tempTaxable = [];
    const tempIgst = [];
    const tempCgst = [];
    const tempSgst = [];
    const tempTotalTax = [];
    Promise.all(
      tax.map(async (id) => {
        const response = await fetch(
          localApi +
            `/taxSumary/list/byAdminIdAndTaxType/${
              userObj && userObj.id
            }?taxType=${id.taxType}`
        );
        const data = await response.json();
        const d = data.filter((f) => taaxId.includes(f.taxId));
        // console.log(data);
        tempTaxable.push(d && d.reduce((a, b) => a + b.taxableAmount, 0));
        tempIgst.push(d && d.reduce((a, b) => a + b.igst, 0));
        tempCgst.push(d && d.reduce((a, b) => a + b.cgst, 0));
        tempSgst.push(d && d.reduce((a, b) => a + b.sgst, 0));
        tempTotalTax.push(d && d.reduce((a, b) => a + b.totalTax, 0));
      })
    ).then(() => {
      var newTaxable = [];
      var newIgst = [];
      var newCgst = [];
      var newSgst = [];
      var newTotalTax = [];

      var newTaxable = tempTaxable.filter(function (elem, pos) {
        return tempTaxable.indexOf(elem) == pos;
      });
      setNewTaxable(newTaxable && newTaxable);

      var newIgst = tempIgst.filter(function (elem, pos) {
        return tempIgst.indexOf(elem) == pos;
      });
      setNewIgst(newIgst && newIgst);

      var newCgst = tempCgst.filter(function (elem, pos) {
        return tempCgst.indexOf(elem) == pos;
      });
      setNewCgst(newCgst && newCgst);
      // console.log(newCgst);

      var newSgst = tempSgst.filter(function (elem, pos) {
        return tempSgst.indexOf(elem) == pos;
      });
      setNewSgst(newSgst && newSgst);
      // console.log(newSgst);

      var newTotalTax = tempTotalTax.filter(function (elem, pos) {
        return tempTotalTax.indexOf(elem) == pos;
      });
      setNewTotalTax(newTotalTax && newTotalTax);
    });
  }

  async function getTerms() {
    const get =
      localApi +
      `/termsAndCondition/byAdminAndClientId/${userObj.admin.id}/${client}`;
    const results = await fetch(get);
    const res = await results.json();
    // console.log(res);
    setTermsClient(res && res);
  }

  useEffect(() => {
    getProd();
    getOrderList();
    getTerms();
  }, []);

  // async function getList() {
  //     const getList = await fetch(headApi + `/order/byClient?clientid=${userObj && userObj.id}&userid=1`);
  //     const res = await getList.json();
  //     const objs = res.OrderData.find((i) => i.id == id)
  //     if (objs === undefined) {
  //         setLoad(false)
  //         history.push("/error-page");
  //     } else {
  //         setLoad(true);
  //         const obj = objs.cartmultid.split(',')
  //         const tempData = [];
  //         Promise.all(
  //             obj.map(async (id) => {
  //                 const response = await fetch(
  //                     headApi + `/cart/byid/${id}`
  //                 );
  //                 const data = await response.json();
  //                 if (data.error === "true") {
  //                     setLoad(false)
  //                     history.push("/error-page");
  //                 } else {
  //                     // console.log(data);
  //                     tempData.push(data);
  //                     setLoad(true)
  //                 }

  //             })
  //         ).then(() => {
  //             // console.log(tempData);
  //             setAddCart(tempData);
  //         });
  //     }
  // }

  // useEffect(() => {
  //     getList();
  // }, []);

  const handleRemove = (c) => {
    setSubmitteds(true);
    setCartId(c);
  };

  const handleConfirm = () => {
    // console.log(cartId.id);
    const cartMulti = addCart.filter((i) => {
      return i.id !== cartId.id;
    });

    // console.log(cartMulti.length);

    const cartMid = cartMulti.map((i) => {
      return i.id;
    });

    // console.log(cartMid);

    const cartClientid = addCart.map((i) => {
      return i.cId;
    });

    // console.log(viewCart);

    // console.log(cartClientid[0]);

    fetch(
      localApi +
        `/cart/update/byAdminAndCartIdAndFalse/${userObj.admin.id}/${cartId.id}`,
      {
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);

          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              cartId: cartMid[0].toString(),
              clientId: cartClientid[0].toString(),
              multiCartId: cartMid,
              amount: Math.round(
                cartMulti.reduce((a, b) => a + b.amount + b.cartPackCharge, 0)
              ),
              advance: Math.round(
                cartMulti.reduce(
                  (a, b) =>
                    a +
                    b.metalGram *
                      b.cartSets.pcs *
                      b.cartBox.box *
                      b.productSize.grandTotal *
                      (b.cartRawPrice.price + b.cartRawPrice.different),
                  0
                )
              ),
              totalBox: cartMulti.reduce(
                (a, b) => a + b.productSize.grandTotal,
                0
              ),
              totalCarton: cartMulti.reduce(
                (a, b) => parseInt(a) + parseInt(b.cartQuantity),
                0
              ),
            }),
          };

          // console.log(requestOptions);

          fetch(
            localApi +
              `/orders/update/byAdminAndOrdersId/${userObj.admin.id}/${id}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            });
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  var totalCal = 0;
  var totalCalMix = 0;
  var totalSum = 0;

  return (
    <div>
      <Helmet>
        <title>Order View</title>
      </Helmet>
      {!load ? (
        <>
          <div className={classesTable.print}>
            <MyHeader
              color="transparent"
              brand="Home"
              links={<MyHeaderLinks dropdownHoverColor="primary" />}
              fixed
              changeColorOnScroll={{
                height: 300,
                color: "primary",
              }}
            />
          </div>
          <div className={classesTable.print}>
            <Parallax
              image={require("assets/img/bg6.jpg").default}
              filter="primary"
              className={classes.parallax}
            >
              <div className={classe.container}>
                <GridContainer justify="center">
                  <GridItem
                    md={8}
                    sm={8}
                    className={classNames(
                      classe.mlAuto,
                      classe.mrAuto,
                      classe.textCenter
                    )}
                  >
                    <h1 className={classe.title}>ORDER DETAILS</h1>
                    <h4></h4>
                  </GridItem>
                </GridContainer>
              </div>
            </Parallax>
          </div>
          <div className={classNames(classeCart.main, classeCart.mainRaised)}>
            <div className={classeCart.container}>
              {submitted ? (
                <SweetAlert
                  type={alertType}
                  style={{ display: "block", marginTop: "-100px" }}
                  title={alertMsg}
                  onConfirm={() => hideAlert()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                />
              ) : null}
              {submitteds ? (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "100px" }}
                  title="Are you sure?"
                  onConfirm={handleConfirm}
                  onCancel={() => hideAlerts()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                  cancelBtnCssClass={
                    classeAlert.button + " " + classeAlert.danger
                  }
                  confirmBtnText="Yes, delete it!"
                  cancelBtnText="Cancel"
                  showCancel
                />
              ) : null}

              {submittedConfirm ? (
                <SweetAlert
                  success
                  style={{ display: "block", marginTop: "100px" }}
                  title="Deleted!"
                  onConfirm={() => hideAlerts()}
                  onCancel={() => hideAlerts()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                >
                  Your imaginary file has been deleted.
                </SweetAlert>
              ) : null}
              <Card plain>
                <CardBody plain>
                  <h3 className={classeCart.cardTitle}>Products Details</h3>
                  <Table
                    tableHead={[
                      <span className={classesTable.tableHead} key="sr">
                        Sr.No.
                      </span>,
                      <span className={classesTable.tableHead} key="des">
                        Description of Goods
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="hsn"
                      >
                        HSN/SAC Code
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="ctn"
                      >
                        Carton
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="qty"
                      >
                        Quantity
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="pri"
                      >
                        Price(₹)
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="amt"
                      >
                        Amount(₹)
                      </span>,
                      // "",
                    ]}
                    tableData={[
                      ...addCart.map((c, index) => [
                        <span className={classesTable.listNumber} key={c.id}>
                          {index + 1}
                        </span>,
                        <span key={c.id}>
                          <span className={classesTable.descParagragh}>
                            IMMITATION BANGLES {" "} {"(Product No. " + c.pId + ")"}
                          </span>
                          <GridContainer className={classesTable.sizePadding}>
                            {c.productSize.s1x8 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : "1x8"}
                                <br />
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : c.productSize.s1x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x10 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : "1x10"}
                                <br />
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : c.productSize.s1x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x12 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : "1x12"}
                                <br />
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : c.productSize.s1x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x14 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : "1x14"}
                                <br />
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : c.productSize.s1x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x0 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : "2x0"}
                                <br />
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : c.productSize.s2x0}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x2 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : "2x2"}
                                <br />
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : c.productSize.s2x2}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x4 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : "2x4"}
                                <br />
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : c.productSize.s2x4}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x6 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : "2x6"}
                                <br />
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : c.productSize.s2x6}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x8 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : "2x8"}
                                <br />
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : c.productSize.s2x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x10 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : "2x10"}
                                <br />
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : c.productSize.s2x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x12 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : "2x12"}
                                <br />
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : c.productSize.s2x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x14 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : "2x14"}
                                <br />
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : c.productSize.s2x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x0 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : "3x0"}
                                <br />
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : c.productSize.s3x0}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          <GridContainer className={classesTable.sizePadding}>
                            {c.productSize.s1x9 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : "1x9"}
                                <br />
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : c.productSize.s1x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x11 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : "1x11"}
                                <br />
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : c.productSize.s1x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x13 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : "1x13"}
                                <br />
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : c.productSize.s1x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x15 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : "1x15"}
                                <br />
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : c.productSize.s1x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x1 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : "2x1"}
                                <br />
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : c.productSize.s2x1}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x3 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : "2x3"}
                                <br />
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : c.productSize.s2x3}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x5 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : "2x5"}
                                <br />
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : c.productSize.s2x5}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x7 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : "2x7"}
                                <br />
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : c.productSize.s2x7}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x9 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : "2x9"}
                                <br />
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : c.productSize.s2x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x11 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : "2x11"}
                                <br />
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : c.productSize.s2x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x13 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : "2x13"}
                                <br />
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : c.productSize.s2x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x15 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : "2x15"}
                                <br />
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : c.productSize.s2x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x1 != 0 ? (
                              <span
                                className={
                                  classesTable.textCenter +
                                  " " +
                                  classesTable.sizeSpan +
                                  " " +
                                  classesTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : "3x1"}
                                <br />
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : c.productSize.s3x1}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          {/* <span className={classesTable.listDescription}>
                          {c && c.name ? c.name + ", " : ""}{" "}
                          {c && c.mycomment ? c.mycomment : ""}
                        </span> */}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.hsnSacCode}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.cartQuantity}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.productSize.grandTotal}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.gstCash === true
                            ? c.taxInclude.toFixed(2)
                            : c.cashPrice.toFixed(2)}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{Math.round(c.amount)}
                        </span>,
                        // cartLength != 1 ? (
                        //   <Tooltip
                        //     id="close1"
                        //     title="Cart Remove"
                        //     placement="left"
                        //     classes={{ tooltip: classeCart.tooltip }}
                        //     key={c.id}
                        //   >
                        //     <Button
                        //       link
                        //       className={classeCart.actionButton}
                        //       onClick={() => {
                        //         handleRemove(c);
                        //       }}
                        //     >
                        //       <Close style={{ color: "red" }} />
                        //     </Button>
                        //   </Tooltip>
                        // ) : (
                        //   ""
                        // ),
                      ]),
                      [
                        "",
                        <span className={classesTable.listNumber} key={1}>
                          Cartoon Charges
                          <br />
                          Grand Total
                        </span>,
                        "",
                        <span key={3}>
                          <br />
                          <span className={classesTable.headRight}>
                            Carton-
                            {addCart.reduce((a, b) => a + b.cartQuantity, 0)}
                          </span>
                        </span>,
                        <span>
                          <br />
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            Unit-
                            {addCart &&
                              addCart.reduce(
                                (a, b) => a + b.productSize.grandTotal,
                                0
                              )}
                          </span>
                        </span>,
                        "",
                        <span key={4}>
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            ₹
                            {addCart.reduce((a, b) => a + b.cartPackCharge * b.cartQuantity, 0) +
                              "/-"}
                          </span>
                          <br />
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            ₹
                            {Math.round(
                              addCart.reduce(
                                (a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity,
                                0
                              )
                            ) + "/-"}
                          </span>
                        </span>,
                        "",
                      ],
                    ]}
                  />
                  <hr />
                  <Button
                    className={classesTable.print}
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                    color="primary"
                    round
                    href={`/order-view-print?id=${id}&client=${client}`}
                  >
                    Go Print
                  </Button>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className={classesTable.print}>
            <MyFooter />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
