/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MyHeader from "views/MyBangles/Section/MyHeader";
import MyHeaderLinks from "views/MyBangles/Section/MyHeaderLinks";
import MyFooter from "views/MyBangles/Section/MyFooter";
import SweetAlert from "react-bootstrap-sweetalert";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import basicsCheck from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import Razor from "assets/img/razorpay.png";
import PayTM from "assets/img/paytm.png";
import ICICI from "assets/img/icici.svg";

const useStyles = makeStyles(profilePageStyle);
const useStyleAlert = makeStyles(style);
const useStylesTable = makeStyles(invoiceStyle);
const useStyleCart = makeStyles(listCart);
const useStyle = makeStyles(basicsCheck);

export default function CartView({ ...rest }) {
  const [addCart, setAddCart] = React.useState([]);
  const [updateAdd, setUpdateAdd] = React.useState([]);
  const [userId, setUserId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [cardId, setCartId] = React.useState();
  const [payNow, setPayNow] = React.useState(false);
  const [selectedEnabled, setSelectedEnabled] = React.useState("");
  const [submitPayment, setSubmitPayment] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [cartShort, setCartShort] = React.useState("");

  const [gstAddress, setGSTAddress] = React.useState("");
  const [shipAddress, setShipAddress] = React.useState("");
  const [branchAddress, setBranchAddress] = React.useState("");
  const [deliAddress, setDeliAddress] = React.useState([]);

  const classes = useStyles();
  const history = useHistory();
  const classesTable = useStylesTable();
  const classeCart = useStyleCart();
  const classeAlert = useStyleAlert();
  const classe = useStyle();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client");
  const address = new URLSearchParams(search).get("address");
  const transport = new URLSearchParams(search).get("transport");
  const reg = new URLSearchParams(search).get("reg");

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const current = new Date();
  const dateCurrent = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`;
  current.setDate(current.getDate() + 10);
  let dateEx = new Date(current).toLocaleDateString("de");

  React.useEffect(() => {
    axios
      .get(
        localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${client}?active=0`
      )
      .then((res) => {
        if (res.data.error === "true") {
          setLoad(false);
          history.push("/error-page");
        } else {
          setLoad(true);
          const cLength = res.data.length === 1 ? res.data : "";
          if (cLength) {
            console.log(cLength[0]);
            setCartShort(cLength[0]);
            setAddCart(res.data && res.data.length != 0 ? res.data : "");
          } else {
            setAddCart(
              res.data && res.data.length != 0
                ? res.data
                : history.push("/error-page")
            );
          }
        }
      });
  }, []);

  React.useEffect(() => {
    const deliveryAdd = [];
    axios
      .get(
        localApi +
          `/regAddress/list/byAdminAndRegisterId/${userObj.admin.id}/${userObj.id}`
      )
      .then((res) => {
        // console.log(res.data);
        // setUpdateAdd(res.data);
        const gstAddress = res.data.filter((a) =>
          a && a.addressType === "GSTIN ADDRESS"
            ? a.addressType === "GSTIN ADDRESS"
            : a.addressType === "AADHAR ADDRESS"
            ? a.addressType === "AADHAR ADDRESS"
            : a.addressType === "OTHER ADDRESS"
        )[0];
        setUpdateAdd(gstAddress && gstAddress);
        const { id, ...restGSTAdd } = Object.assign({}, gstAddress);
        setGSTAddress(restGSTAdd);
        deliveryAdd.push(restGSTAdd);
      });

    axios.get(localApi + `/regAddress/byId/${address}`).then((r) => {
      const { id, ...restShipAdd } = Object.assign({}, r.data);
      setShipAddress(restShipAdd);
      deliveryAdd.push(restShipAdd);
    });

    setDeliAddress(deliveryAdd);

    axios.get(localApi + `/branch/findById/${transport}`).then((b) => {
      const { id, ...restBranchAdd } = Object.assign({}, b.data);
      setBranchAddress(restBranchAdd);
    });
  }, []);

  const { state } = useLocation();

  const handleitemRemove = (c, submitteds) => {
    setSubmitteds(true);
  };

  const handleConfirm = () => {
    // console.log(cardId);
    axios
      .delete(
        localApi +
          `/cart/delete/byAdminAndCartId/${userObj.admin.id}/${cardId.id}`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
        if (cartShort) {
          history.push(
            `/bangles-size-mm-list?catdesc=${
              cartShort && cartShort.cartCategory.title
            }&cat1desc=${
              cartShort && cartShort.cartParent.title
            }&Plating=Gold%20Plating`
          );
        }
      });
    setSubmittedConfirm(true);
  };

  const AdvancePayRazor = () => {
    var options = {
      key: "rzp_live_SsKrYvXeVM5elD",
      amount: Math.round((totalSum = totalCalMix + totalCal)) * 100,
      // amount: Math.round(addCart.reduce((a, b) => a + b.metalOption.metalgram * b.sets.pcs * b.box.box * b.total * b.metal.price, 0)) * 100,
      currency: "INR",
      name: "Sharif H M",
      description: "Insha Bangles",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // console.log(response);
        if (response.razorpay_payment_id) {
          setSubmitPayment(true);
          alertTypeOps("success");
          alertMsgOps(
            `₹ ${
              Math.round((totalSum = totalCalMix + totalCal)) + "/- "
            } Congratulation your Transaction is Successfully`
          );
          // alertMsgOps(`₹ 1/- Congratulation your Transaction is Successfully`);
          const cartMid = addCart.map((i) => {
            return i.id;
          });

          cartMid.forEach((x) =>
            axios
              .put(
                headApi + `/cart/active?id=${x}&clientid=${userObj.id}&active=1`
              )
              .then((res) => {
                // console.log(res.data);
              })
          );

          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              cartid: cartMid[0].toString(),
              cartmultid: cartMid.toString(),
              clientid: userObj.id,
              shippingid: state,
              userid: userObj.referenceid,
              amount: Math.round(
                addCart.reduce(
                  (a, b) => a + b.taxableamt + b.totalcartoncharge,
                  0
                )
              ),
              advance: Math.round((totalSum = totalCalMix + totalCal)),
              totalbox: addCart.reduce((a, b) => a + b.total, 0),
              totalcarton: addCart.reduce(
                (a, b) => parseInt(a) + parseInt(b.ctnquantity),
                0
              ),
              status: 0,
              paymentstatus: 0,
              deliverystatus: 0,
            }),
          };

          fetch(headApi + "/order", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              // history.push("/my-order");
              // console.log(data);
              // if (data.message === "Order Placed Successfully !!! ") {
              //   setSubmitted(true);
              //   alertTypeOps("success");
              //   alertMsgOps("Congratulation your Order is Successfully placed");
              //   // history.push("/order-list");
              // }
            });
        }
      },
      prefill: {
        name: userObj.firstname + "" + userObj.lastname,
        email: userObj.email,
        contact: userObj.mobile,
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#528FF0",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  const FullPayRazor = () => {
    var options = {
      key: "rzp_live_SsKrYvXeVM5elD",
      amount:
        Math.round(
          addCart.reduce((a, b) => a + b.taxableamt + b.totalcartoncharge, 0)
        ) * 100,
      currency: "INR",
      name: "Sharif H M",
      description: "Insha Bangles",
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        if (response.razorpay_payment_id) {
          setSubmitPayment(true);
          alertTypeOps("success");
          alertMsgOps(
            `₹ ${
              Math.round(
                addCart.reduce(
                  (a, b) => a + b.taxableamt + b.totalcartoncharge,
                  0
                )
              ) + "/- "
            } Congratulation your Transaction is Successfully`
          );
          const cartMid = addCart.map((i) => {
            return i.id;
          });

          cartMid.forEach((x) =>
            axios
              .put(
                headApi + `/cart/active?id=${x}&clientid=${userObj.id}&active=1`
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Cart Updated Successfully!!! ") {
                  setSubmitted(true);
                  alertTypeOps("success");
                  alertMsgOps(
                    "Congratulation your Order is Successfully placed"
                  );
                }
              })
          );

          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              cartid: cartMid[0].toString(),
              cartmultid: cartMid.toString(),
              clientid: userObj.id,
              shippingid: state,
              userid: userObj.referenceid,
              amount: Math.round(
                addCart.reduce(
                  (a, b) => a + b.taxableamt + b.totalcartoncharge,
                  0
                )
              ),
              advance: Math.round((totalSum = totalCalMix + totalCal)),
              totalbox: addCart.reduce((a, b) => a + b.total, 0),
              totalcarton: addCart.reduce(
                (a, b) => parseInt(a) + parseInt(b.ctnquantity),
                0
              ),
              status: 0,
              paymentstatus: 0,
              deliverystatus: 0,
            }),
          };

          fetch(headApi + "/order", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              // history.push("/my-order");
              // console.log(data);
              // if (data.message === "Order Placed Successfully !!! ") {
              //   setSubmitted(true);
              //   alertTypeOps("success");
              //   alertMsgOps("Congratulation your Order is Successfully placed");
              //   // history.push("/order-list");
              // }
            });
        }
      },
      prefill: {
        name: userObj.firstname + "" + userObj.lastname,
        email: userObj.email,
        contact: userObj.mobile,
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#528FF0",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  const confirmOrder = () => {
    const cartMid = addCart.map((i) => {
      return i.id;
    });

    const cartM = addCart.map((i) => {
      return {
        cartId: i.id,
        adminId: i.c_adminid,
        clientId: i.cId,
      };
    });

    const cartClientid = addCart.map((i) => {
      return i.cId;
    });

    const sType = addCart.map((m) => m.saleType)[0];
    const gcType = addCart.map((m) => m.gstCash)[0];

    // cartMid.forEach((x) =>
    //   axios
    //     .put(
    //       localApi + `/cart/update/byAdminAndCartId/${userObj.admin.id}/${x}`
    //     )
    //     .then((res) => {
    //       // console.log(res.data);
    //       if (res.data.message === "Data Updated Successfully !!!") {
    //         setSubmitted(true);
    //         alertTypeOps("success");
    //         alertMsgOps("Congratulation");
    //       }
    //     })
    // );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        cartId: cartMid[0].toString(),
        clientId: cartClientid[0].toString(),
        ordersCart: cartM,
        firstName: userObj.firstname,
        lastName: userObj.lastname,
        company: userObj.company,
        deliveryAddress: deliAddress,
        transbranch: branchAddress,
        saleType: sType,
        gstCash: gcType,
        amount: Math.round(
          addCart.reduce(
            (a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity,
            0
          )
        ),
        advance: Math.round(
          addCart.reduce(
            (a, b) =>
              a +
              b.metalGram *
                b.cartSets.pcs *
                b.cartBox.box *
                b.productSize.grandTotal *
                (b.cartRawPrice.price + b.cartRawPrice.different),
            0
          )
        ),
        totalBox: addCart.reduce((a, b) => a + b.productSize.grandTotal, 0),
        totalCarton: addCart.reduce((a, b) => a + b.cartQuantity, 0),
        status: 0,
        paymentStatus: 0,
        deliveryStatus: 0,
      }),
    };

    fetch(localApi + `/orders/add/${userObj.admin.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Inserted Successfully !!!") {
          setSubmitted(true);
          alertTypeOps("success");
          alertMsgOps("Congratulation");
          cartMid.forEach((x) =>
            axios
              .put(
                localApi +
                  `/cart/update/byAdminAndCartId/${userObj.admin.id}/${x}`
              )
              .then((res) => {
                // console.log(res.data);
                // if (res.data.message === "Data Updated Successfully !!!") {
                //   setSubmitted(true);
                //   alertTypeOps("success");
                //   alertMsgOps("Congratulation");
                // }
              })
          );
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    history.push("/my-order");
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const hideAlertPayment = () => {
    setSubmitPayment(false);
    history.push("/my-order");
  };

  var totalCal = 0;
  var totalCalMix = 0;
  var totalSum = 0;

  return (
    <div>
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>
      {load ? (
        <>
          <MyHeader
            color="transparent"
            brand="Home"
            links={<MyHeaderLinks dropdownHoverColor="primary" />}
            fixed
            changeColorOnScroll={{
              height: 300,
              color: "primary",
            }}
          />
          <Parallax
            image={require("assets/img/bg6.jpg").default}
            filter="primary"
            className={classes.parallax}
          ></Parallax>
          <div className={classNames(classeCart.main, classeCart.mainRaised)}>
            <div className={classeCart.container}>
              <Card plain>
                <CardBody plain>
                  {submitted ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={
                        classeAlert.button + " " + classeAlert.success
                      }
                    >
                      Your Order is Successfully placed
                    </SweetAlert>
                  ) : null}

                  {submitteds ? (
                    <SweetAlert
                      warning
                      style={{ display: "block", marginTop: "100px" }}
                      title="Are you sure?"
                      onConfirm={handleConfirm}
                      onCancel={() => hideAlerts()}
                      confirmBtnCssClass={
                        classeAlert.button + " " + classeAlert.success
                      }
                      cancelBtnCssClass={
                        classeAlert.button + " " + classeAlert.danger
                      }
                      confirmBtnText="Yes, delete it!"
                      cancelBtnText="Cancel"
                      showCancel
                    >
                      That you want to remove this item from your cart?
                    </SweetAlert>
                  ) : null}

                  {submittedConfirm ? (
                    <SweetAlert
                      success
                      style={{ display: "block", marginTop: "100px" }}
                      title="Deleted!"
                      onConfirm={() => hideAlerts()}
                      onCancel={() => hideAlerts()}
                      confirmBtnCssClass={
                        classeAlert.button + " " + classeAlert.success
                      }
                    >
                      Your item has been deleted.
                    </SweetAlert>
                  ) : null}

                  {submitPayment ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlertPayment()}
                      confirmBtnCssClass={
                        classeAlert.button + " " + classeAlert.success
                      }
                    />
                  ) : null}

                  <h3 className={classeCart.cardTitle}>Shopping Cart</h3>
                  <Table
                    tableHead={[
                      <span className={classesTable.tableHead} key="sr">
                        Sr.
                        <br />
                        No.
                      </span>,
                      "",
                      <span className={classesTable.tableHead} key="des">
                        Description of Goods
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="ctn"
                      >
                        Carton
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="qty"
                      >
                        Quantity
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="pri"
                      >
                        Price
                      </span>,
                      <span
                        className={
                          classesTable.tableHead + " " + classesTable.headRight
                        }
                        key="amt"
                      >
                        Amount
                      </span>,
                      "",
                    ]}
                    tableData={[
                      ...addCart.map((c, index) => [
                        <span className={classesTable.listNumber} key={c.id}>
                          {index + 1}
                        </span>,
                        <div className={classeCart.imgContainer} key={c.id}>
                          {/* Date: {c.timedate.date.slice(0, -12)} */}
                          {/* Date: {c.createDate.slice(0, -19)} */}
                          {"Product No. " + c.pId}
                          <img
                            src={
                              c.cartImage[0].cropImageUrl +
                              "?id=" +
                              c.cartImage[0].imgId
                            }
                            className={classes.img}
                            style={{ height: "140px" }}
                          />
                        </div>,
                        <span key={c.id}>
                          <h6 className={classesTable.listDescription}>
                            {c.cartChild.map((f) =>
                              f.title === "Plating Gold Plated"
                                ? f.title.slice(8)
                                : ""
                            )}{" "}
                            {c.cartParent.title} {c.cartRawPrice.title} <br />
                            {c.cartCategory.title} with {c.cartPacking.type}
                            <br />
                            {c.readyGram * c.cartSets.pcs} Avg Gram of{" "}
                            {c.cartSets.setDesc}
                            <br />
                            Sets Price :{" "}
                            {c.gstCash === false
                              ? (c.cashPrice / c.cartBox.box).toFixed(2)
                              : (c.taxInclude / c.cartBox.box).toFixed(2)}
                            /- {c.gstCash === false ? "Cash" : "IncTax"}
                            <br />
                            Metal Rate : ₹{" "}
                            {c.cartRawPrice.price + c.cartRawPrice.different}/-
                            Advance : ₹
                            {Math.round(
                              c.metalGram *
                                c.cartSets.pcs *
                                c.cartBox.box *
                                c.productSize.grandTotal *
                                (c.cartRawPrice.price +
                                  c.cartRawPrice.different)
                            )}
                          </h6>

                          <GridContainer className={classesTable.sizePadding}>
                            {c.productSize.s1x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : "1x8"}
                                <br />
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : c.productSize.s1x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : "1x10"}
                                <br />
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : c.productSize.s1x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : "1x12"}
                                <br />
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : c.productSize.s1x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : "1x14"}
                                <br />
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : c.productSize.s1x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : "2x0"}
                                <br />
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : c.productSize.s2x0}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x2 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : "2x2"}
                                <br />
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : c.productSize.s2x2}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x4 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : "2x4"}
                                <br />
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : c.productSize.s2x4}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x6 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : "2x6"}
                                <br />
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : c.productSize.s2x6}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x8 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : "2x8"}
                                <br />
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : c.productSize.s2x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x10 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : "2x10"}
                                <br />
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : c.productSize.s2x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x12 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : "2x12"}
                                <br />
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : c.productSize.s2x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x14 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : "2x14"}
                                <br />
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : c.productSize.s2x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x0 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : "3x0"}
                                <br />
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : c.productSize.s3x0}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          <GridContainer className={classesTable.sizePadding}>
                            {c.productSize.s1x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : "1x9"}
                                <br />
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : c.productSize.s1x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : "1x11"}
                                <br />
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : c.productSize.s1x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : "1x13"}
                                <br />
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : c.productSize.s1x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : "1x15"}
                                <br />
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : c.productSize.s1x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : "2x1"}
                                <br />
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : c.productSize.s2x1}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x3 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : "2x3"}
                                <br />
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : c.productSize.s2x3}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x5 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : "2x5"}
                                <br />
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : c.productSize.s2x5}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x7 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : "2x7"}
                                <br />
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : c.productSize.s2x7}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x9 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : "2x9"}
                                <br />
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : c.productSize.s2x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x11 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : "2x11"}
                                <br />
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : c.productSize.s2x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x13 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : "2x13"}
                                <br />
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : c.productSize.s2x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x15 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : "2x15"}
                                <br />
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : c.productSize.s2x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x1 != 0 ? (
                              <span
                                className={
                                  classes.textCenter +
                                  " " +
                                  classes.sizeSpan +
                                  " " +
                                  classes.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : "3x1"}
                                <br />
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : c.productSize.s3x1}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          {/* <span className={classesTable.listDescription}>{c && c.name ? c.name + ", " : ""} {c && c.mycomment ? c.mycomment : ""}</span> */}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.cartQuantity}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.productSize.grandTotal}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹
                          {c.gstCash === true
                            ? c.taxInclude.toFixed(2)
                            : c.cashPrice.toFixed(2)}
                        </span>,
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{Math.round(c.amount)}
                        </span>,
                        <Tooltip
                          id="close1"
                          title="Cart Remove"
                          placement="left"
                          classes={{ tooltip: classeCart.tooltip }}
                          key={c.id}
                        >
                          <Button
                            link
                            className={classeCart.actionButton}
                            onClick={() => {
                              handleitemRemove(c.id);
                              setCartId(c);
                            }}
                          >
                            <Close style={{ color: "red" }} />
                          </Button>
                        </Tooltip>,
                      ]),
                      [
                        "",
                        <span className={classesTable.listNumber} key={1}>
                          Cartoon Charges
                          <br />
                          Grand Total
                        </span>,
                        <span className={classesTable.listNumber} key={2}>
                          <br />
                          Advance - ₹{" "}
                          {Math.round(
                            addCart.reduce(
                              (a, b) =>
                                a +
                                b.metalGram *
                                  b.cartSets.pcs *
                                  b.cartBox.box *
                                  b.productSize.grandTotal *
                                  (b.cartRawPrice.price +
                                    b.cartRawPrice.different),
                              0
                            )
                          ) + "/-"}
                        </span>,
                        <span key={3}>
                          <br />
                          <span className={classesTable.headRight}>
                            Carton-
                            {addCart.reduce((a, b) => a + b.cartQuantity, 0)}
                          </span>
                        </span>,
                        <span>
                          <br />
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            Unit-
                            {addCart &&
                              addCart.reduce(
                                (a, b) => a + b.productSize.grandTotal,
                                0
                              )}
                          </span>
                        </span>,
                        "",
                        <span key={4}>
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            ₹
                            {addCart.reduce(
                              (a, b) => a + b.cartPackCharge * b.cartQuantity,
                              0
                            ) + "/-"}
                          </span>
                          <br />
                          <span
                            className={
                              classesTable.headRight +
                              " " +
                              classesTable.listNumber
                            }
                          >
                            ₹
                            {Math.round(
                              addCart.reduce(
                                (a, b) =>
                                  a +
                                  b.amount +
                                  b.cartPackCharge * b.cartQuantity,
                                0
                              )
                            ) + "/-"}
                          </span>
                        </span>,
                        "",
                      ],
                    ]}
                  />
                  <hr />
                  <GridContainer>
                    <GridItem md={12} sm={12} xs={12}>
                      <ul>
                        {/* <li>Payment : Advance <span className={classesTable.termDescription}>Rs. {addCart.reduce((a, b) => a + b.metalOption.metalgram * b.sets.pcs * b.box.box * b.total * b.metal.price, 0).toFixed(2)}/-</span> of total amount along with purchase order 20% when dispatchof 1st shipment and Remaining 15% will be payable on confirmation of completion of goods ordered,</li> */}
                        {/* <li>Delivery: 50% of the total goods will be delivered in the first shipment. Remaining 50% will be delivered on the receipt of total outstanding amount of whole purchase order (I.E remaining 15%),</li> */}
                        <li style={{ color: "red" }}>
                          Quantity +/- 3% tolerance acceptance.
                        </li>
                        <li style={{ color: "red" }}>
                          Packing and Transportation charges will be extra.
                        </li>
                        <li style={{ color: "red" }}>
                          Insurance covered, if paid 3% of the total purchase.
                        </li>
                        {/* <li>Proposal is valid till <span className={classesTable.termDescription}>{dateEx.replaceAll('.', '-')}</span> from,</li> */}
                      </ul>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{
                      float: "right",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <GridItem>
                      <Button color="primary" round onClick={confirmOrder}>
                        Confirm Order
                      </Button>{" "}
                      &nbsp;&nbsp;
                      <Button
                        color="primary"
                        round
                        onClick={() => {
                          setPayNow(true);
                          window.scroll({
                            top: document.body.offsetHeight,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Pay Now
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
              </Card>
            </div>
          </div>
          {payNow === true ? (
            <div className={classes.container}>
              <GridContainer justify="center" alignItems="center">
                <GridItem md={12} sm={12}>
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem md={4} sm={4}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={selectedEnabled === "a"}
                                onChange={() => setSelectedEnabled("a")}
                                value="a"
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={classe.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classe.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classe.radio,
                                  root: classe.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classe.label,
                              root: classe.labelRoot,
                            }}
                            label={<img src={Razor} />}
                          />
                        </GridItem>
                        <GridItem md={4} sm={4}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={selectedEnabled === "b"}
                                onChange={() => setSelectedEnabled("b")}
                                value="b"
                                name="radio button enabled"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classe.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classe.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classe.radio,
                                  root: classe.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classe.label,
                              root: classe.labelRoot,
                            }}
                            label={<img src={PayTM} />}
                          />
                        </GridItem>
                        <GridItem md={4} sm={4}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={selectedEnabled === "c"}
                                onChange={() => setSelectedEnabled("c")}
                                value="c"
                                name="radio button enabled"
                                aria-label="C"
                                icon={
                                  <FiberManualRecord
                                    className={classe.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classe.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classe.radio,
                                  root: classe.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classe.label,
                              root: classe.labelRoot,
                            }}
                            label={<img src={ICICI} width={160} />}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        {selectedEnabled === "a" ? (
                          <GridItem md={12} sm={12}>
                            <Button
                              color="primary"
                              round
                              // onClick={AdvancePayRazor}
                            >
                              Advance Payment
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                              color="primary"
                              round
                              // onClick={FullPayRazor}
                            >
                              Full Payment
                            </Button>
                          </GridItem>
                        ) : selectedEnabled === "b" ? (
                          <GridItem md={12} sm={12}>
                            <Button color="primary" round>
                              Advance Payment
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color="primary" round>
                              Full Payment
                            </Button>
                          </GridItem>
                        ) : selectedEnabled === "c" ? (
                          <GridItem md={12} sm={12}>
                            <Button color="primary" round>
                              Advance Payment
                            </Button>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color="primary" round>
                              Full Payment
                            </Button>
                          </GridItem>
                        ) : (
                          ""
                        )}
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          ) : (
            ""
          )}
          <br />
          <MyFooter />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
