import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>We build awesome products</h2>
          <h5 className={classes.description}>
            The design may change, choose with your range.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="1. Design"
            description={
              <span>
                <p>
                  Our designs never go out of fashion. We designed and crafted
                  them at our own manufacturing facility to develop a product of
                  superior quality to give perfect original gold bangles.
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Gesture}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="2. Develop"
            description={
              <span>
                <p>
                  The raw material we use is of fine quality each and every
                  piece is produced under strict quality production, and also we
                  give a 6-month colour guarantee on our bangles. As we are a
                  manufacturer, we give bangles at factory prices (discounted
                  rates).
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Build}
            iconColor="rose"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="3. Make Edits"
            description={
              <span>
                <p>
                  We make bangles that are loved by all age group ladies. We
                  give you an opportunity to customize the bangles according to
                  your choice and taste so that you feel the joy of wearing the
                  best bangles.
                </p>
                {/* <a href="#pablo">Find more...</a> */}
              </span>
            }
            icon={Build}
            iconColor="rose"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
