import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function PrivacyDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>Privacy Policy</h3>
          <h5 className={classes.description}>
            Madna Sharif Hussain operates the{" "}
            <a
              href="https://mybangles24.com"
              target={"_blank"}
              rel="noreferrer"
            >
              mybangles24.com
            </a>{" "}
            website, which provides the facilitation of bangles for the purpose
            of daily wear and bridal occasions.
            <br />
            This page is used to inform website visitors regarding our policies
            regarding the collection, use, and disclosure of Personal
            Information if anyone decides to use our Service.
            <br />
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
            <br />
            This privacy policy sets out how{" "}
            <a
              href="https://mybangles24.com"
              target={"_blank"}
              rel="noreferrer"
            >
              mybangles24.com
            </a>{" "}
            uses and protects any information that you give{" "}
            <a
              href="https://mybangles24.com"
              target={"_blank"}
              rel="noreferrer"
            >
              mybangles24.com
            </a>{" "}
            when you use this website.
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>
            Use of website server log information for IT security purposes
          </h3>
          <h5 className={classes.description}>
            Our third-party hosting provider stores server logs to ensure
            network and IT security and so that the server and website remain
            uncompromised. This includes analyzing log files to help identify
            and prevent unauthorized access to our network, the distribution of
            malicious code, denial of services attacks and other cyber attacks,
            by detecting unusual or suspicious activity. Unless we are
            investigating suspicious or potential criminal activity, we do not
            make, nor do we allow our website server provider to make any
            attempt to identify you from the information collected via server
            logs.
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>
            Information we collect when you contact us
          </h3>
          <ul>
            <li className={classes.description}>Email Id</li>
            <li className={classes.description}>Contact Number</li>
            <li className={classes.description}>Name</li>
            <li className={classes.description}>Company Name</li>
            <li className={classes.description}>Address</li>
            <li className={classes.description}>
              Measurement Sizes(If applicable)
            </li>
          </ul>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>
            Information we collect when you interact with our website
          </h3>
          <ul>
            <li className={classes.description}>
              Subscription/Newsletters <br /> You can opt into receiving
              marketing communications from us in relation to our goods and
              services by keeping the {"'"}I would like to receive offers about
              products and services{"'"}. Box ticked and giving a confirmation
              on the same when engaging with our portals. We will send you
              marketing communications in relation to our goods and services
              only if you opt-in to receive them. When you give consent to our
              e-newsletter to receive information news and offers about our
              products and services on our website we collect your email address
              device OS and country code. This information is stored with our
              3rd party mail server providers as well as our hosting servers.
            </li>
            <br />
            <li className={classes.description}>
              Registration/ Checkout/ Guest Checkout <br /> When you register
              and create an account on our website or at checkout, we collect
              the following information: your name, your shipping address
              (including country, street address, town/city and postcode), your
              billing address (including country, street address, town/city and
              postcode) phone number, email address which we store on our
              servers for better user experience next time the same customer
              visits our portal.
            </li>
            <br />
            <li className={classes.description}>
              Information collected while placing an order <br /> After you
              place an order on our website you will need to make payment for
              the goods or services you have ordered. In order to process your
              payment we use a number of third party payment processors,
              including Razor Pay and Paytm, Your payment will be processed by
              the payment provider you choose to process your payment via a
              payment gateway.
            </li>
            <br />
            <li className={classes.description}>
              Third-party payment processors <br /> The third-party payment
              processors we use to collect and process your information,
              including payment information, in accordance with their privacy
              policies. You can access their privacy policies via the following
              links: <br />{" "}
              <a href="https://razorpay.com" target="_blank" rel="noreferrer">
                Razorpay
              </a>{" "}
              <br />{" "}
              <a href="https://paytm.com" target="_blank" rel="noreferrer">
                Paytm.com
              </a>{" "}
            </li>
            <br />
          </ul>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>
            Disclosure and additional uses of your information
          </h3>
          <h4 style={{ textTransform: "uppercase" }}>
            Disclosure to Service Providers
          </h4>
          <h5 className={classes.description}>
            We use a number of third parties to provide us with services that
            are necessary to run our business or to assist us with running our
            business and who process your information for us on our behalf.
            These include the following:
          </h5>
          <ul>
            <li className={classes.description}>Email provider</li>
            <li className={classes.description}>Mailing list provider</li>
            <li className={classes.description}>IT service providers</li>
            <li className={classes.description}>Web developers</li>
            <li className={classes.description}>Hosting provider</li>
          </ul>
          <h5 className={classes.description}>
            Your information will be shared with these service providers only
            where necessary to enable us to run our business.
          </h5>
          <h4 style={{ textTransform: "uppercase" }}>
            Disclosure for Legal Reasons
          </h4>
          <ul>
            <li className={classes.description}>
              Indicating possible criminal acts or threats to public security to
              a competent authority
            </li>
            <li className={classes.description}>
              In connection with the enforcement or potential enforcement of our
              legal
            </li>
            <li className={classes.description}>
              In connection with a legal or potential legal dispute or
              proceedings
            </li>
            <li className={classes.description}>
              For ongoing compliance with laws, regulations and other legal
              requirements
            </li>
          </ul>
          <h4 style={{ textTransform: "uppercase" }}>
            Disclosure to other 3rd Party Providers
          </h4>
          <h5 className={classes.description}>
            We may provide user data to 3rd party providers which includes but
            is not limited to the parties mentioned below
          </h5>
          <ul>
            <li className={classes.description}>
              Providing information to 3rd Party Analytics Partners
            </li>
            <li className={classes.description}>Accountants</li>
            <li className={classes.description}>Advisors</li>
            <li className={classes.description}>Affiliates</li>
            <li className={classes.description}>Business Partners</li>
            <li className={classes.description}>Independent Contractors</li>
            <li className={classes.description}>Insurers</li>
            <li className={classes.description}>Auditors</li>
            <li className={classes.description}>Sponsors</li>
          </ul>
          <h4 style={{ textTransform: "uppercase" }}>
            How long we retain your information
          </h4>
          <h5 className={classes.description}>
            when you place an order for goods and services, we retain that
            information for a minimum period of seven years following the end of
            the financial year in which you placed your order, in accordance
            with our legal obligation to keep records <br />
            Criteria for Determining the retention period
            <br />
            In any other circumstances, we will retain your information for no
            longer than necessary, taking into account the following:
          </h5>
          <ul>
            <li className={classes.description}>
              The purpose(s) and use of your information both now and in the
              future (such as whether it is necessary to continue to store that
              information in order to continue to perform our obligations under
              a contract with you or to contact you in the future);
            </li>
            <li className={classes.description}>
              Whether we have any legal obligation to continue to process your
              information (such as any record-keeping obligations imposed by
              relevant law or regulation);
            </li>
            <li className={classes.description}>
              Whether we have any legal basis to continue to process your
              information (such as your consent); how valuable your information
              is (both now and in the future);
            </li>
            <li className={classes.description}>
              Any relevant agreed industry practices on how long information
              should be retained;
            </li>
            <li className={classes.description}>
              The levels of risk, cost and liability involved with us continuing
              to hold the information;
            </li>
            <li className={classes.description}>
              How hard it is to ensure that the information can be kept up to
              date and accurate; and any relevant surrounding circumstances
              (such as the nature and status of our relationship with you).
            </li>
          </ul>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>Business Transfers</h3>
          <h5 className={classes.description}>
            Information about{" "}
            <a
              href="https://mybangles24.com"
              target={"_blank"}
              rel="noreferrer"
            >
              mybangles24.com
            </a>{" "}
            users, including personal information, may be disclosed as part of
            any merger, acquisition, debt financing, sale of company assets, as
            well as in the event of an insolvency, bankruptcy or receivership in
            which personal information could be transferred to third parties as
            one of the business assets of Insha Bangles Online Services Private
            Limited.
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>
            Miscellaneous Privacy Issues
          </h3>
          <h5 className={classes.description}>
            Minors under the age of eighteen may not use the website. We do not
            collect information from anyone known to be under the age of
            eighteen and no part of the website is designed to attract underage
            customers. We sell children{"'"}s products for purchase by adults.
            If you are under eighteen you may use Inshabangles.com only with the
            involvement of a parent or guardian.
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>Service</h3>
          <h5 className={classes.description}>
            The quality of the products ordered was part excellence. The
            delivery was on time and the designs were absolutely as shown on the
            website.
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>Security</h3>
          <h5 className={classes.description}>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>Contact Us</h3>
          <h5 className={classes.description}>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to{" "}
            <a
              href="https://mybangles24.com/contact-us"
              target={"_blank"}
              rel="noreferrer"
            >
              contact us
            </a>
            .
          </h5>
        </GridItem>
      </GridContainer>
      <br />
    </div>
  );
}
